<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="{ 'documentation documentation-scrollbar': true, 'no-code': page.page_codes.length === 0 }">
    <div class="information">
      <h5 class="information__title">
        {{ page.title }}
      </h5>
      <pre
        class="information__description"
        v-html="page.description"
      />
      <div
        v-if="page.params.length > 0"
        class="information__query"
      >
        <h6 class="information__query__title">
          Query Parameters
        </h6>
        <div class="information__query__parameters">
          <div class="information__query__parameters__heading">
            <span class="information__query__parameters__heading__label">Parameter</span>
            <span class="information__query__parameters__heading__label">Description</span>
          </div>
          <div
            v-for="(query, index) in page.params"
            :key="index"
            class="information__query__parameters__content"
          >
            <span class="information__query__parameters__content__description">{{ query.title }}</span>
            <span class="information__query__parameters__content__description">{{ query.description }}</span>
          </div>
        </div>
      </div>
      <pre
        class="information__content"
        v-html="page.page_content"
      />
    </div>
    <codes
      v-if="page.page_codes.length"
      :languages="page.page_codes"
    />
  </div>
</template>

<script>
import Codes from './Codes.vue';

export default {
  components: { Codes },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
pre {
  font-family: Montserrat;
  white-space: pre-wrap;
}

.documentation {
  display: grid;
  @include grid-cols-2;
  gap: 0.1rem;
  width: 100%;
  @include cms-height;
  margin: $cms-margin-y 0.2rem;

  &.no-code {
    @include grid-cols-1;
  }

  @include tablet-and-less {
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: minmax(0, 1fr);

    &.no-code {
      grid-template-rows: minmax(0, 1fr);
    }
  }

  .information {
    border-radius: 8px;
    padding: 0.32rem 0.24rem;
    background: #FFFFFF;
    overflow-y: auto;

    &__title {
      font-weight: 700;
      font-size: 0.2rem;
      line-height: 0.24rem;
      color: $text-primary;
    }

    &__description {
      margin-top: 0.1rem;
      font-size: 0.14rem;
      line-height: 0.2rem;
      color: $text-tertiary;
    }

    &__content {
      margin-top: 0.16rem;
      font-size: 0.14rem;
      line-height: 0.2rem;
      color: $text-tertiary;
    }

    &__query {
      padding: 0.16rem;
      margin-top: 0.32rem;
      border-radius: 4px;
      background: #F3F6FF;

      &__title {
        margin-bottom: 0.08rem;
        font-size: 0.14rem;
        color: #3A71FF;
      }

      &__parameters {
        display: flex;
        flex-direction: column;

        &__heading {
          display: grid;
          @include grid-cols-2;
          width: 100%;
          padding: 0.08rem 0;
          border-bottom: 1px solid #CBD0E2;

          &__label {
            color: $text-primary;
            font-size: 0.14rem;
            font-weight: 600;
            text-transform: uppercase;
          }
        }

        &__content {
          display: grid;
          @include grid-cols-2;
          width: 100%;
          padding: 0.08rem 0;

          &__description {
            color: $text-primary;
            font-size: 0.12rem;
          }
        }
      }
    }
  }
}
</style>
