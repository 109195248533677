<template>
  <div id="cms-docs">
    <nav class="nav">
      <template v-for="page in pages">
        <router-link
          :key="page.id"
          :to="page.path"
          :class="linkClasses(page)"
        >
          <span class="nav__link__title">{{ page.title }}</span>
          <span class="nav__link__code">{{ page.code }}</span>
        </router-link>
        <router-link
          v-for="child in page.children"
          :key="child.id"
          :to="child.path"
          :class="linkClasses(child, true)"
        >
          <span class="nav__link__title">/{{ child.title }}</span>
          <span class="nav__link__code">/{{ child.code }}</span>
        </router-link>
      </template>
    </nav>
    <documentation
      v-if="activePage"
      :page="activePage"
    />
  </div>
</template>
<script>
import arrow from '@/assets/images/icons/arrow-right-white.svg';
import Documentation from './Documentation.vue';

export default {
  components: { Documentation },
  data: () => ({
    pages: [],
    pagesMap: {},
    active: 0,
    arrow,
  }),
  computed: {
    activePage() {
      return this.pagesMap[this.active];
    },
    param() {
      return parseInt(this.$route.params.docLocation, 10);
    },
  },
  watch: {
    '$route.params.docLocation': function paramChanged() {
      if (Number.isNaN(this.param)) {
        if (this.pages.length === 0) return;

        const page = this.pages.find((p) => p.default);
        this.active = page ? page.id : this.pages[0].id;
      } else {
        this.active = this.param;
      }
    },
    pages(pages) {
      if (pages.length === 0) return;

      if (!Number.isNaN(this.param)) {
        this.active = this.param;
        return;
      }

      const page = pages.find((p) => p.default);

      this.active = page ? page.id : pages[0].id;
    },
  },
  async beforeCreate() {
    const response = await this.$api.cms.docs.getPagesDocumentations();
    const pagesMap = response.data.reduce((pages, page) => {
      const updatedPage = page;
      const map = pages;

      updatedPage.children = [];
      updatedPage.path = `/cms/docs/${page.id}`;
      map[page.id] = page;

      return pages;
    }, {});

    response.data.forEach((page) => {
      if (!page.parent_page) return;

      const parent = pagesMap[page.parent_page];

      if (!parent.children) parent.children = [];

      parent.children.push(page);
    });

    this.pagesMap = pagesMap;
    this.pages = Object.values(pagesMap).filter((page) => !page.parent_page);
  },
  methods: {
    isActive(id) {
      return id === parseInt(this.active, 10);
    },
    isOpened(id) {
      return id === this.active || (this.activePage && id === this.activePage.parent_page);
    },
    linkClasses(page, isChild) {
      const active = this.isActive(page.id);
      const classes = { nav__link: true, active };
      const siblingOrParentIsActive = this.activePage.parent_page === page.parent_page || this.active === page.parent_page;

      if (isChild) return { ...classes, hidden: !active && !siblingOrParentIsActive };

      return {
        ...classes,
        parent: true,
        default: page.default,
        'has-children': page.children.length > 0,
        open: this.isOpened(page.id),
        active: this.isActive(page.id) || page.id === this.activePage.parent_page,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$margin-right: 0.08rem;
$arrow-down-icon: url(~@/assets/images/icons/arrow-down-white.svg);
$arrow-right-icon: url(~@/assets/images/icons/arrow-right-white.svg);
$active-gradient: linear-gradient(90.27deg, rgba(255, 255, 255, 0.1) 2.04%, rgba(255, 255, 255, 0) 102.65%);

#cms-docs {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - #{$menu-height} - 0.06rem);

  @include tablet-and-less {
    min-height: calc(100vh - #{$menu-height} - (#{$cms-margin-y} - 0.05rem));
  }

  .nav {
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    height: calc(100vh - #{$menu-height} - 0.06rem);
    max-height: 100%;
    width: 100%;
    max-width: fit-content;
    padding-top: 0.8rem;
    background: #1B134C;
    overflow-y: auto;

    @include tablet-and-less {
      height: calc(100vh - #{$menu-height} - (#{$cms-margin-y} - 0.05rem));
    }

    @include mobile {
      min-width: fit-content;
    }

    &__link {
      position: relative;
      display: flex;
      align-items: center;
      height: 0.46rem;
      padding: 0 0.24rem 0 0.2rem;
      margin-right: $margin-right;
      white-space: nowrap;
      font-size: 0.14rem;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.6);

      &__code {
        display: none;
      }

      @include mobile {
        &__title {
          display: none;
        }

        &__code {
          display: block;
        }

        &.default {
          .nav__link__title {
            display: block;
          }
          .nav__link__code {
            display: none;
          }
        }
      }

      &.hidden {
        display: none;
      }

      &.has-children {
        background-image: $arrow-right-icon;
        background-repeat: no-repeat;
        background-position: right center;
      }

      &.active {
        color: rgb(255, 255, 255);

        &.parent {
          background-image: $active-gradient;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: -$margin-right;
            height: 100%;
            width: 2px;
            background: #3A71FF;
          }
        }

        &.has-children {
          background-image: $active-gradient, $arrow-down-icon;
        }
      }
    }
  }
}
</style>
