<template>
  <div
    ref="code"
    :class="{ 'code documentation-scrollbar': true, overflow: isOverflowing }"
  >
    <h6 class="code__title">
      Code
    </h6>
    <p class="code__description">
      Reference code for calling our API
    </p>
    <el-tabs
      v-model="selected"
      type="border-card"
      @tab-click="(tab) => selected = tab.name"
    >
      <template v-for="([title, codes]) in Object.entries(languagesMap)">
        <el-tab-pane
          :key="title"
          :name="title"
          :label="title"
        >
          <div
            v-for="section in codes"
            :key="section.id"
            class="code__section"
          >
            <h6
              v-if="section.title"
              class="code__section__title"
            >
              {{ section.title }}
            </h6>
            <div class="code__section__wrapper">
              <pre class="code__section__block">{{ section.codeblock }}</pre>
            </div>
          </div>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>

export default {
  props: {
    languages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({ selected: '', languagesMap: {}, isOverflowing: false }),
  watch: {
    languages(languages) {
      if (languages.length === 0) return;

      this.mapLanguages();
    },
    selected() {
      this.resized();
    },
  },
  mounted() {
    window.addEventListener('resize', this.resized);
    this.resized();

    if (this.languages.length === 0) return;

    this.mapLanguages();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized);
  },
  methods: {
    mapLanguages() {
      const map = {};
      this.languages.forEach((language) => {
        if (map[language.code.title]) return map[language.code.title].push(language);

        map[language.code.title] = [];
        return map[language.code.title].push(language);
      });
      this.languagesMap = map;
      this.selected = Object.keys(map)[0];
    },
    resized() {
      this.$nextTick(() => {
        const element = this.$refs.code;
        this.isOverflowing = element.scrollHeight > element.clientHeight;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  display: flex;
  flex-direction: column;
  @include cms-height;

  @include tablet-and-less {
    height: auto;
  }

  &__title {
    font-weight: 700;
    font-size: 0.18rem;
    color: $text-primary;
  }

  &__description {
    margin-top: 0.02rem;
    font-size: 0.14rem;
    line-height: 0.2rem;
    color: $text-tertiary;
  }

  &__section {
    padding: 0.16rem 0;

    &__title {
      margin-bottom: 0.08rem;
      font-weight: 600;
      line-height: 0.2rem;
      color: $text-primary;
    }

    &__wrapper {
      padding: 0.08rem;
      border-radius: 4px;
      background: #2C5BD6;
    }

    &__block {
      margin: 0;
      padding: 0.08rem 0 0.16rem 0;
      font-size: 0.14rem;
      font-family: Montserrat, Helvetica Neue, Helvetica, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      line-height: 0.2rem;
      color: #FFFFFF;
      overflow: auto;
      background: #2C5BD6;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 0.06rem;
      height: 0.06rem;
      border-radius: 32px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 32px;
      background: rgba(255, 255, 255, 0.3);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-radius: 32px;
      background: #FAFAFA;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #FFFFFF;
    }
  }

  ::v-deep {
    .el-tabs {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: calc(100% - (#{$cms-margin-y} * 2));
      margin-top: 0.16rem;
      border: none;
      box-shadow: none;
      background: transparent;

      &__header {
        border-bottom: none;
        background: transparent;
      }

      &__item {
        padding: 0.04rem 0.08rem !important;
        border: none;
        height: fit-content;
        outline: none;
        font-size: 0.14rem;
        font-weight: 500;
        line-height: 0.2rem;

        &.is-active {
          position: relative;
          padding: 0.04rem 0.08rem !important;
          border-radius: 8px 8px 0 0;
          background: #FFFFFF;

          &::before {
            content: '';
            position: absolute;
            top: 4px;
            right: 0;
            bottom: 0;
            left: 0;
            width: calc(100% + 4px);
            height: calc(100% + 8px);
            border-radius: 8px 8px 0 0;
            background: #FFFFFF;
            z-index: -1;
            transform: rotate(-15deg);
          }

          &:not(:first-child)::after {
            content: '';
            position: absolute;
            top: 4px;
            right: 0;
            bottom: 0;
            left: -4px;
            width: calc(100% - 4px);
            height: calc(100% + 8px);
            border-radius: 8px 8px 0 0;
            background: #FFFFFF;
            z-index: -1;
            transform: rotate(15deg);
          }
        }
      }

      &__content {
        height: 100%;
        overflow: auto;
        padding: 0.16rem;
        border-radius: 0 8px 8px 8px;
        background: #FFFFFF;

        > div {
          height: 100%;
        }
      }
    }
  }
}
</style>
